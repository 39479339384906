<nav *transloco="let t; read: 'shop.nav'" aria-label="Main Navigation">
  <ul
    class="flex flex-row bg-neutral-100 min-h-11 text-black uppercase items-start w-full h-full overflow-hidden md:overflow-hidden border-b-neutral-200 md:border-b-4 md:items-end md:justify-evenly md:flex-row md:w-auto text-sm md:text-base lg:text-lg">
    <li>
      <a
        data-test="new"
        [routerLink]="'/nowe-produkty' | mzkLink | async"
        routerLinkActive="active">
        <fast-svg name="nav-stars" size="18" />
        <span class="font-semibold">
          {{ t('news') }}
        </span>
      </a>
    </li>

    <!-- <li>
      <a
        data-test="new"
        [routerLink]="'/nowe-produkty' | mzkLink | async"
        routerLinkActive="active">
        <fast-svg name="nav-stars" size="18" />
        <span class="font-semibold">
          {{ t('categories') }}
        </span>
      </a>
    </li> -->

    <!-- @for (category of categories$ | async; track category?.id) {
      <li
        attr.data-test="home-nav-category-{{ category.id }}"
        [ngClass]="{ parent: category.isExpandable }">
        <a [routerLink]="category?.path" routerLinkActive="active">
          <span>
            <img
              src="https://manzuko.b-cdn.net/assets/images/p/{{
                category?.productImageId
              }}.jpg?width=100"
              alt="{{ category?.name }}"
              loading="lazy"
              class="rounded-full aspect-square border border-neutral-200 p-[1px] mr-5 md:hidden" />
            <span class="flex flex-grow">
              {{ category?.displayName || category?.name }}
            </span>
          </span>
        </a>
      </li>
    } -->

    <li>
      <a
        data-test="nav-promotion"
        [routerLink]="'/promocje' | mzkLink | async"
        routerLinkActive="active"
        class="text-brand-700">
        <fast-svg name="nav-promotion" size="18" />
        <span class="font-semibold">
          {{ t('sale') }}
        </span>
      </a>
    </li>

    <li>
      <a
        data-test="nav-bestseller"
        [routerLink]="'/najczesciej-kupowane' | mzkLink | async"
        routerLinkActive="active">
        <fast-svg name="nav-bestseller" size="18" />
        <span class="font-semibold">
          {{ t('best') }}
        </span>
      </a>
    </li>
  </ul>
</nav>

<nav class="relative flex flex-row">
  <ul class="flex flex-row items-center overflow-x-auto gap-x-1 px-2 w-full justify-between">
    @for (category of categories$ | async; track category?.id) {
      <li class="inline-block py-1" attr.data-test="home-nav-category-{{ category.id }}">
        <a
          class="flex flex-col items-center min-h-11 relative text-center text-sm uppercase"
          [routerLink]="category?.path"
          routerLinkActive="active">
          <img
            class="inline-block w-10 h-10 rounded-full border border-neutral-200"
            src="https://manzuko.b-cdn.net/assets/images/p/{{
              category?.productImageId
            }}.jpg?width=100"
            alt="{{ category?.name }}"
            loading="eager"
            fetchpriority="high" />

          <span class="whitespace-normal sm:whitespace-nowrap">
            {{ category?.displayName || category?.name }}
          </span>
        </a>
      </li>
    }
  </ul>

  <div
    class="absolute top-0 right-0 bottom-0 w-30 bg-gradient-to-l from-white to-transparent z-20 hidden"></div>
</nav>
